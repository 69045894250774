import { format, distanceInWords, differenceInDays } from "date-fns";
import React from "react";
import Img from "gatsby-image";
import { buildImageObj, localImage } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import PortableText from "./portableText";
import Container from "./container";
import AuthorList from "./author-list";
import TalkyardCommentsIframe from "@debiki/gatsby-plugin-talkyard";

import styles from "./blog-post.module.css";

function BlogPost(props) {
  // console.log(props);
  const { _rawBody, authors, categories, title, mainImage, publishedAt } = props;
  return (
    <article className={styles.root}>
      {mainImage && mainImage.asset && (
        // <div className={styles.mainImage}>
        //   <img
        //     src={imageUrlFor(buildImageObj(mainImage))
        //       .width(1200)
        //       .height(Math.floor((9 / 16) * 1200))
        //       .fit("crop")
        //       .auto("format")
        //       .url()}
        //     alt={mainImage.alt}
        //   />
        // </div>
        <Img className={styles.mainImage} fluid={localImage(props)} />
      )}
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <h1 className={styles.title}>{title}</h1>
            {_rawBody && <PortableText blocks={_rawBody} />}

            <TalkyardCommentsIframe />
          </div>
          <aside className={styles.metaContent}>
            {publishedAt && (
              <div className={styles.publishedAt}>
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? distanceInWords(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), "DD/MM/YYYY")}
              </div>
            )}
            {authors && <AuthorList items={authors} title="Autor" />}
            {categories && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Categorias</h3>
                <ul>
                  {categories.map(category => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
            )}
          </aside>
        </div>
      </Container>
    </article>
  );
}

export default BlogPost;
